import Image from "next/image";
import { useState, useMemo } from "react";

interface INewImageFormatProps {
  src: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive" | undefined;
  objectFit?: "cover" | "contain" | "fill" | "none";
  alt?: string;
  quality?: number;
  objectPosition?: string;
  width?: string;
  height?: string;
  usePlaceholder?: boolean;
  relative?: boolean;
  absolute?: boolean;
  className?: string;
}

const NewImageFormat = ({
  src,
  layout,
  objectFit,
  alt,
  quality,
  objectPosition,
  width,
  height,
  relative,
  usePlaceholder,
  absolute,
  className,
}: INewImageFormatProps) => {
  // TODO: When next/future/image leaves experimental. Implement below.
  // https://nextjs.org/docs/api-reference/next/future/image
  // For next/future/image we can replace the layout, objectFit, and objectPosition for style props
  const [imageSize, setSameSize] = useState({
    width: "100%",
    height: "100%",
  });

  // removes error with having both width and height and layout "fill"
  const widthHeightUnused = useMemo(() => {
    if (layout === "fill") {
      return {
        width: null,
        height: null,
      };
    }
  }, [layout]);

  if (absolute && relative) {
    throw new Error("Only use relative OR absolute on NewImageFormat props.");
  }

  const localClassNames = useMemo(() => {
    const stack = [className || ""];
    if (relative) stack.push("relative");
    if (absolute) stack.push("absolute");

    return stack.join(" ");
  }, [className, relative, absolute]);

  return (
    <div className={localClassNames}>
      <Image
        src={src}
        layout={layout}
        objectFit={objectFit}
        alt={alt}
        quality={quality}
        objectPosition={objectPosition}
        onLoadingComplete={(target) => {
          setSameSize({
            width: target.naturalWidth as unknown as string,
            height: target.naturalHeight as unknown as string,
          });
        }}
        placeholder={usePlaceholder ? "blur" : undefined}
        blurDataURL={usePlaceholder ? src : undefined}
        width={widthHeightUnused ? width : imageSize.width}
        height={widthHeightUnused ? height : imageSize.height}
      />
    </div>
  );
};

export default NewImageFormat;
