import NewImageFormat from "src/components/NewImageFormat";

const Custom404 = () => {
  return (
    <main className="h-screen bg-black">
      <NewImageFormat
        className="w-full opacity-[.15] h-full z-0"
        src="/ERROR_BACKGROUND.jpg"
        quality={100}
        layout="fill"
        objectFit="cover"
        alt="404 Background Image"
        usePlaceholder
        absolute
      />

      <div className="flex justify-center items-center h-screen center text-white">
        <div className="flex justify-center items-center flex-col">
          <h1 className="text-8xl mb-10 text-white font-extralight">404</h1>
          <h2 className="mb-5 uppercase text-center sm:text-base md:text-2xl font-semibold">
            We couldn&apos;t find that page
          </h2>
        </div>
      </div>
    </main>
  );
};

export default Custom404;
